(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./modules/modals');

require('./modules/forms');

require('./modules/masks');

require('./modules/spinner');

require('./modules/scroll');

require('./modules/menus');

require('./modules/tabs');

require('./modules/galleries');

require('./modules/sliders');

require('./modules/spoilers');

require('./modules/catalogue');

require('./modules/card');

require('./modules/sharing');

require('./modules/filter');

require('./modules/tooltips');

require('./modules/order');

require('./modules/card-choose-offer');

},{"./modules/card":4,"./modules/card-choose-offer":3,"./modules/catalogue":5,"./modules/filter":6,"./modules/forms":7,"./modules/galleries":8,"./modules/masks":11,"./modules/menus":12,"./modules/modals":13,"./modules/order":14,"./modules/scroll":15,"./modules/sharing":16,"./modules/sliders":17,"./modules/spinner":18,"./modules/spoilers":19,"./modules/tabs":20,"./modules/tooltips":21}],2:[function(require,module,exports){
module.exports={
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1440
  }
}

},{}],3:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./vue-components/PriceComponent');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListComponent = require('./vue-components/OffersListComponent');

var _OffersListComponent2 = _interopRequireDefault(_OffersListComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Подключение в common.js
 *   import './modules/card-choose-offer';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-price-app" class="product-card__controls-column" data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */
window.initPriceApp = function () {
  if (!$('#js-card-price-app').length) return;

  var cardPriceApp = new Vue({
    el: '#js-card-price-app',
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-component': _OffersListComponent2.default
    },
    data: {
      offer: {},
      targetButton: ''
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
      });
    },
    watch: {
      //Обнавляем данные на кнопке купить
      offer: function offer(val, oldVal) {
        var data = $(this.targetButton).data();
        $.extend(data, val.basketData);
        $(this.targetButton).data(data);
      }
    }
  });
};

window.initPriceApp();

},{"./vue-components/OffersListComponent":22,"./vue-components/PriceComponent":23}],4:[function(require,module,exports){
'use strict';

$('.js-toggle-review-form').click(function () {
  $('#reviews-form').toggleClass('is-hidden');
});

$('.js-scroll-to-responses').click(function (e) {
  e.preventDefault();

  $(window).scrollTo($('#product-tabs'), { duration: 200, offset: -$('.header').height() });
  $('#product-tabs .tabs-nav [href="#reviews"]').click();
});

},{}],5:[function(require,module,exports){
'use strict';

$(function () {
  $(document).on('yiiListViewUpdated lazyPicsInit', function () {
    $('.js-lazy-pic').unveil(200);
  }).trigger('lazyPicsInit');
  $('body').on('iasRender', function () {
    $('.js-lazy-pic').unveil(200);
  });

  setTimeout(function () {
    $('.hidden-lazy-pic').each(function () {
      $(this).attr('src', $(this).data('src'));
    });
  }, 300);
});

},{}],6:[function(require,module,exports){
'use strict';

$(document).on('click', '.js-toggle-filter-group', function () {
  $(this).closest('fieldset').toggleClass('is-collapsed');
});

$(document).on('click', '.js-toggle-filter', function (e) {
  e.preventDefault();
  $('body').toggleClass('filter-is-opened');
});

},{}],7:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
  $('.js-checkbox input').trigger('initCheckboxes');
  $('.js-radio input').trigger('initRadio');
}).trigger('initSelectric');

// Checkboxes
// ----------

_globals.$DOCUMENT.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$DOCUMENT.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selected_item = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selected_item.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Fileinputs
// ----------

$('.js-fileinp').change(function () {
  var $this = $(this);
  var $filename = $this.parent().find('.filename');
  $filename.text($this[0].files[0].name);
});

// Rating input
// ------------

$('.js-rating-input .star').click(function () {
  var $this = $(this);
  var $widget = $this.closest('.js-rating-input');
  var $inp = $widget.find('input');
  var rating = $this.index() + 1;

  $widget.find('.star').removeClass('is-filled');
  $widget.find('.star:lt(' + rating + ')').addClass('is-filled');
  $inp.val(rating);
});

// Touched inputs state
// --------------------

$('.form-field').find('.inp, .textarea').on('focus', function () {
  $(this).closest('.form-field').addClass('is-touched');
}).on('blur', function () {
  var $this = $(this);
  var value = $this.val();

  if (value != '' && value != null) return;

  $this.closest('.form-field').removeClass('is-touched');
}).on('inputs-init', function () {
  var $this = $(this);
  var value = $this.val();

  if (value != '' && value != null) {
    $this.closest('.form-field').addClass('is-touched');
  }
}).trigger('inputs-init');

// Password fields
// ---------------

$('.js-toggle-password').click(function (e) {
  e.preventDefault();
  var $inp = $(this).parent().toggleClass('password-shown').find('.inp');
  if ($inp.attr('type') == 'password') {
    $inp.attr('type', 'text');
  } else {
    $inp.attr('type', 'password');
  }
  $inp.focus();
});

},{"./globals":9}],8:[function(require,module,exports){
"use strict";

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfig = require('../media-queries-config.json');

var _mediaQueriesConfig2 = _interopRequireDefault(_mediaQueriesConfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

// Custom vh for mobile devices
// ----------------------------

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

},{"../media-queries-config.json":2}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_HANDHELD_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals');

var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_HANDHELD_WIDTH = exports.IS_HANDHELD_WIDTH = function IS_HANDHELD_WIDTH() {
  return WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals":9}],11:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

(function () {

  $('input[type="tel"]').mask('+7 (999) 999-99-99', {
    autoclear: false
  });

  if (_globals.IS_DESKTOP) {
    $('input[type="date"]').attr('type', 'text');

    // Date
    $('.js-date-mask').mask('99/99/9999', {
      placeholder: 'дд.мм.гггг',
      autoclear: false
    });

    // Time
    $('.js-time-mask').mask('99:99', {
      placeholder: 'чч:мм',
      autoclear: false
    });
  }
})();

},{"./globals":9}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
        $this.closest('.dropdown').addClass('has-active-dropdown');
        var $dropdown = $this.find('.dropdown:first');
        var $parent = $this.closest('.dropdown');
        if ($parent.length) {
          $dropdown[0].style.setProperty('--offset', 0);
          // $dropdown.css('minWidth', 0);
          $dropdown.css('width', 'auto');
          setTimeout(function () {
            $dropdown.css('width', 'clamp(320px, ' + ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20) + 'px, calc(100vw - ' + $dropdown.offset().left + 'px))');
            $dropdown.css('width', 'clamp(320px, ' + ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20) + 'px, calc(100vw - ' + $dropdown.offset().left + 'px))');
            //$dropdown.css('minWidth', ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20));
            //$dropdown.css('minWidth', ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20));
            var offset = $dropdown.offset().top + $dropdown.outerHeight() - $(window).height();
            if (offset > 0) {
              $dropdown[0].style.setProperty('--offset', -offset + 'px');
            }
          }, 0);
        }
      }, 100));
    } else {
      $this.addClass('is-hovered');
      $this.closest('.dropdown').addClass('has-active-dropdown');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
      $this.closest('.dropdown').removeClass('has-active-dropdown');
    }, 100));
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-m-menu', 'a, .has-dropdown > span', function (e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var has_dropdown = $parent.hasClass('has-dropdown');
    var is_hovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href') && $anchor.attr('href')[0] != '#' ? $anchor.attr('href') : false;

    $parent.siblings().removeClass('is-hovered');

    if (!has_dropdown) {
      if (link) {
        location.href = link;
      }
    } else {
      // if (is_hovered && link) {
      //   location.href = link;
      // } else {
      $parent.toggleClass('is-hovered');
      if ($parent.hasClass('is-hovered')) {
        $anchor.closest('.dropdown').addClass('has-active-dropdown');
        var $dropdown = $anchor.closest('li').find('.dropdown:first');
        var _$parent = $anchor.closest('li').closest('.dropdown');
        if (_$parent.length) {
          $dropdown[0].style.setProperty('--offset', 0);
          // $dropdown.css('minWidth', 0);
          $dropdown.css('width', 'auto');
          setTimeout(function () {
            $dropdown.css('width', 'clamp(320px, ' + ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20) + 'px, calc(100vw - ' + $dropdown.offset().left + 'px))');
            $dropdown.css('width', 'clamp(320px, ' + ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20) + 'px, calc(100vw - ' + $dropdown.offset().left + 'px))');
            // $dropdown.css('minWidth', ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20));
            // $dropdown.css('minWidth', ($dropdown.children().last().position().left + $dropdown.children().last().outerWidth(true) + 20));
            var offset = $dropdown.offset().top + $dropdown.outerHeight() - $(window).height();
            if (offset > 0) {
              $dropdown[0].style.setProperty('--offset', -offset + 'px');
            }
          }, 0);
        }
      } else {
        $anchor.closest('.dropdown').removeClass('has-active-dropdown');
      }
      // }
    }
  });
}

$('.js-toggle-mobile-menu').click(function (e) {
  e.preventDefault();

  var opened_class = 'menu-is-opened';

  _globals.$BODY.toggleClass(opened_class);

  if (_globals.$BODY.hasClass(opened_class)) {
    _globals.$BODY.on('click.close-menu', function (e) {
      var $target = $(e.target);

      if ($target.closest('.mobile-menu').length || $target.hasClass('js-toggle-mobile-menu')) {
        e.stopPropagation();
        return false;
      }

      _globals.$BODY.removeClass(opened_class).off('click.close-menu');
    });
  } else {
    _globals.$BODY.removeClass(opened_class).off('click.close-menu');
  }
});

$('.js-toggle-menu-item').click(function (e) {
  e.preventDefault();
  e.stopPropagation();

  $(this).closest('.has-dropdown').toggleClass('is-hovered');
});

$('.js-toggle-mobile-search').click(function () {
  if ($(window).width() <= _globals.TABLET_WIDTH) {
    $('.header__search').stop(true, true).slideToggle(100);
  } else {
    $('.header__search').toggleClass('is-visible');
    if ($('.header__search').hasClass('is-visible')) {
      $(window).on('scroll.hide-search', function () {
        $('.header__search').removeClass('is-visible');
        $(window).off('scroll.hide-search');
      });
    } else {
      $(window).off('scroll.hide-search');
    }
  }
});

$('.js-mobile-search .btn').click(function () {
  $(this).closest('form').submit();
});

$('.js-show-more-menu-items').on('mouseenter', function () {
  var $menu = $(this).closest('.js-menu');
  var $extraMenuItems = $menu.find('> ul > li:gt(3)');

  $menu.addClass('is-expanded');

  $('.js-show-more-menu-items').add($extraMenuItems).on('mouseleave.collapse-menu', function (e) {
    if ($(e.toElement).hasClass('.js-show-more-menu-items') || $extraMenuItems.filter(e.toElement).length || $extraMenuItems.filter($(e.toElement).closest('li')).length) return;
    $('.js-show-more-menu-items').add($extraMenuItems).off('mouseleave.collapse-menu');
    $menu.removeClass('is-expanded');
  });
});

$('.js-toggle-catalogue-menu').click(function (e) {
  // let opened_class = 'menu-is-opened';
  e.preventDefault();
  var $this = $(this);
  var $menu = $($this.attr('href'));
  $menu.toggle();
  $this.toggleClass('is-active');
  if ($this.hasClass('is-active')) {
    // $('body').addClass(opened_class);
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
      $menu.data('ps').update();
    }
    $(document).on('click.close-catalogue-menu', function (e) {
      var $target = $(e.target);
      if ($target.closest('.catalogue-menu').length || $target.hasClass('js-toggle-catalogue-menu')) {
        return;
      }
      e.stopPropagation();
      // $('body').removeClass(opened_class);
      $this.removeClass('is-active');
      $menu.hide();
      $(document).off('click.close-catalogue-menu');
    });
  } else {
    // $('body').removeClass(opened_class);
    $(document).off('click.close-catalogue-menu');
  }
});

$('.js-toggle-catalogue-menu-category').click(function (e) {
  if ($(this).closest('li').hasClass('is-active')) return;
  e.preventDefault();
  $(this).closest('li').addClass('is-active').siblings().removeClass('is-active');
  // if (!IS_LANDSCAPE_MOBILE_WIDTH()) {
  //   $(this).closest('li').find('.js-menu-scrollbar').data('ps').update();
  // }
});

$('.js-toggle-mobile-phone').click(function (e) {
  e.preventDefault();
  var $this = $(this);
  var $popup = $($this.attr('href'));
  $popup.toggle();

  $('.js-toggle-mobile-phone').toggleClass('is-active');

  if ($this.hasClass('is-active')) {
    $(document).on('click.close-phones-popup', function (e) {
      var $target = $(e.target);
      if ($target.closest('.phones-popup').length || $target.hasClass('js-toggle-mobile-phone')) {
        return;
      }
      e.stopPropagation();
      $('.js-toggle-mobile-phone').removeClass('is-active');
      $popup.hide();
      $(document).off('click.close-phones-popup');
    });
  } else {
    $(document).off('click.close-phones-popup');
  }
});

$('.js-toggle-mobile-footer-catalogue').click(function () {
  $(this).parent().toggleClass('is-expanded');
});

},{"./globals":9,"./helpers":10}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Open popup
_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function (e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, {
    node: $popup,
    hideSelector: '.js-close-popup',
    fixedElements: '.js-fixed-element'
  });
});

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

},{"./globals":9}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

var $orderAside = $('.js-order-aside');

if ($orderAside.length && !(0, _helpers.IS_MOBILE_WIDTH)()) {
  var PANEL_OFFSET_TOP = 80;
  var PANEL_OFFSET_BOTTOM = 150;
  var $container = $('.order-page__layout');
  var asideHeight = $orderAside.outerHeight() + PANEL_OFFSET_BOTTOM;
  var asidePosition = $orderAside.offset().top;
  var bottomLimit = $container.offset().top + $container.height();
  var maxOffset = bottomLimit - asideHeight;

  _globals.$WINDOW.on('scroll.order-page-scroll', function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    var offset = scroll_top - asidePosition + PANEL_OFFSET_TOP;

    if (offset < 0) {
      $orderAside.css('top', 0);
    } else if (offset >= 0) {
      if (scroll_top < maxOffset - PANEL_OFFSET_TOP) {
        $orderAside.css('top', offset);
      } else if (maxOffset > asideHeight) {
        $orderAside.css('top', maxOffset - asidePosition);
      }
    }
  });
}

$('.js-toggle-history').click(function () {
  $(this).toggleClass('is-collapsed');
});

},{"./globals":9,"./helpers":10}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

// Scroll to
// ---------


$(function () {
  _globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function (e) {
    e.preventDefault();

    var $lnk = $(this);
    var $elem_to_scroll = $($lnk.attr('href'));
    var speed = $lnk.data('speed') || 150;
    var offset = $lnk.data('offset') || 0;

    if ($(this).hasClass('js-chars-tab__link')) {
      $('.js-tabs').each(function () {
        $(this).find('.tabs-nav li:first a').click();
      });
    }

    if ($(this).hasClass('js-delivery-tab__link')) {
      $('.js-tabs').each(function () {
        $(this).find('.tabs-nav li:last a').click();
      });
    };

    _globals.$WINDOW.scrollTo($elem_to_scroll, { duration: speed, offset: offset });
  });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $go_top_btn = $('<div class="go-top-btn"></div>');

  $go_top_btn.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    if (scroll_top > 0) {
      $go_top_btn.addClass('is-visible');
    } else {
      $go_top_btn.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($go_top_btn);
}

// Header state
// ------------

$(window).on('resize initWindowState', function () {
  if (!(0, _helpers.IS_HANDHELD_WIDTH)()) {
    $(window).on('scroll.headerState initScrollState', function () {
      if ($(window).scrollTop() > 200) {
        $('.header').addClass('is-collapsed');
      } else {
        $('.header').removeClass('is-collapsed');
      }
    }).trigger('initScrollState');
  } else {
    $('.header').addClass('is-collapsed');
    $(window).off('scroll.headerState');
  }
}).trigger('initWindowState');

// Custom scrollbar
// ----------------

$('.js-scrollbar').each(function () {
  $(this).data('ps', new PerfectScrollbar($(this)[0]));
});

if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
  $('.js-menu-scrollbar').each(function () {
    $(this).data('ps', new PerfectScrollbar($(this)[0]), { wheelPropagation: false });
    $(this).on('wheel', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    var ps = new PerfectScrollbar($(this)[0]);
    $(this).on('mouseenter', function (e) {
      ps.update();
    });
  });
}

// Scrolled state
// --------------

// let scrollPos = $(window).scrollTop();
// let wait = false;

// $WINDOW.scroll(function() {
//   if (!wait) {
//     checkScrollPos();
//     wait = true;
//     setTimeout(() => { wait = false }, 150);
//   }
// });

// function checkScrollPos() {
//   const newScrollPos = $WINDOW.scrollTop();
//   if (newScrollPos > 10) {
//     $BODY.addClass('scrolledFromTop');
//     if (newScrollPos > scrollPos) {
//       $BODY.addClass('scrolledDown').removeClass('scrolledUp');
//     } else {
//       $BODY.addClass('scrolledUp').removeClass('scrolledDown');
//     }
//   } else {
//     $BODY.removeClass('scrolledFromTop');
//   }
//   scrollPos = newScrollPos;
// }

$(window).scroll(function () {
  if ($(window).scrollTop() > 0) {
    $('body').addClass('scrolledFromTop');
  } else {
    $('body').removeClass('scrolledFromTop');
  }
});

},{"./globals":9,"./helpers":10}],16:[function(require,module,exports){
'use strict';

$('.js-toggle-sharing').click(function () {
  $(this).parent().toggleClass('is-active');
});

},{}],17:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

$('.js-slideshow').each(function () {
  var $this = $(this);

  $this.slick({
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    pauseOnHover: false
  });
});

$('.js-vitrine-vertical-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    vertical: true,
    verticalSwiping: true,
    responsive: [{
      breakpoint: 1024 + 1,
      settings: {
        vertical: false,
        verticalSwiping: false
      }
    }, {
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        vertical: false,
        verticalSwiping: false,
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        vertical: false,
        verticalSwiping: false,
        slidesToShow: 2
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        vertical: false,
        verticalSwiping: false,
        slidesToShow: 1,
        arrows: false,
        dots: true
      }
    }]
  }).on('afterChange', function () {
    $(document).trigger('lazyPicsInit');
  });;
});

$(document).on('yiiListViewUpdated vitrineSlidersInit', function () {
  $('.js-vitrine-slider:not(.slick-initialized)').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    $this.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $parent,
      responsive: [{
        breakpoint: _globals.NOTEBOOK_WIDTH + 1,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 2,
          touchThreshold: 100
        }
      }, {
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 1,
          touchThreshold: 100,
          arrows: false,
          dots: true
        }
      }]
    });
  });
}).trigger('vitrineSlidersInit');

$('.js-leader-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    rows: 2,
    // slidesPerRow: 5,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    // asNavFor: '.js-leader-slider-thumbs',
    responsive: [{
      breakpoint: _globals.NOTEBOOK_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 2,
        touchThreshold: 100
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        touchThreshold: 100,
        arrows: false,
        dots: true,
        rows: 1
      }
    }]
  });
});

// $('.js-leader-slider-thumbs').each(function() {
//   const $this = $(this);
//   const $parent = $this.parent();

//   $this.slick({
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     infinite: false,
//     arrows: false,
//     asNavFor: '.js-leader-slider',
//     responsive: [
//       {
//         breakpoint: NOTEBOOK_WIDTH + 1,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: TABLET_WIDTH + 1,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: PORTRAIT_TABLET_WIDTH + 1,
//         settings: {
//           slidesToShow: 2,
//           touchThreshold:100,
//         },
//       },
//       {
//         breakpoint: SMALL_MOBILE_WIDTH + 1,
//         settings: {
//           slidesToShow: 1,
//           touchThreshold:100,
//         },
//       },
//     ],
//   });
// });

if (_globals.$WINDOW.width() < _globals.SMALL_MOBILE_WIDTH + 1) {
  $('.js-uslick-on-mobile').slick('unslick');
}

$('.js-brands-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 2
      }
    }]
  });
});

$('.js-gallery-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }]
  });
});

$('.js-shop-features-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $(window).on('resize initFeaturesSlider', function () {
    if ((0, _helpers.IS_DESKTOP_WIDTH)()) {
      if ($this.find('.slick-slide').length) {
        $this.slick('unslick');
      }
    } else {
      if (!$this.find('.slick-slide').length) {
        $this.slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: true,
          appendArrows: $parent,
          responsive: [{
            breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
            settings: {
              slidesToShow: 2
            }
          }, {
            breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
            settings: {
              slidesToShow: 1
            }
          }]
        });
      }
    }
  }).trigger('initFeaturesSlider');
});

$(function () {
  $('.js-gallery').each(function () {
    var $this = $(this);

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      adaptiveHeight: true,
      asNavFor: '.js-gallery-thumbs',
      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          dots: true,
          asNavFor: false
        }
      }]
    });
  });

  $('.js-gallery-thumbs').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    $this.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $parent,
      asNavFor: '.js-gallery',
      focusOnSelect: true
    });
  });
});

$('.js-info-gallery').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

},{"./globals":9,"./helpers":10}],18:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function () {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('.inp');
  var step = $input.data('step') ? parseFloat($input.data('step')) : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseFloat($input.val());
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  $spinner_control.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-down')) {
    decVal();dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-up')) {
    incVal();inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(smartRound(value)).change();
    $input[0].dispatchEvent(new Event('input'));
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(smartRound(value)).change();
        $input[0].dispatchEvent(new Event('input'));
      }
    } else {
      if (smartRound(value) > smartRound(step)) {
        value = value - step;
        $input.val(smartRound(value)).change();
        $input[0].dispatchEvent(new Event('input'));
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);clearInterval(dec_interval);
    clearTimeout(inc_timeout);clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('blur', '.js-spinner .inp', function () {
  var $input = $(this);
  var value = $input.val();
  var step = $input.data('step') ? parseFloat($input.data('step')) : 1;
  $input.val(roundRecalc(value, step));
  $input[0].dispatchEvent(new Event('input'));
});

_globals.$DOCUMENT.on('keydown', '.js-spinner .inp', function (e) {
  var $input = $(this);

  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner .inp', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() == 0 || $input.val() == '') {
    if (!may_be_zero) {
      $input.val(1);
      $input[0].dispatchEvent(new Event('input'));
    } else {
      $input.val(0);
      $input[0].dispatchEvent(new Event('input'));
    }
  }
});

function roundRecalc(val, step) {
  return smartRound(Math.ceil(val / step) * step);
}

function smartRound(val) {
  if (String(val).indexOf('.') !== -1) {
    val = val.toFixed(2);
    val = String(val).replace(/0$/, '');
    val = Number(val.replace(/.0$/, ''));
  }
  return val;
}

},{"./globals":9}],19:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers');

$(document).on('click', '.js-toggle-review', function () {
  $(this).closest('.item').toggleClass('is-expanded');
});

$('.js-toggle-categories-list').click(function () {
  $(this).closest('.categories-list').toggleClass('is-expanded');
});

$('.js-toggle-catalogue-text').click(function () {
  $(this).closest('.js-catalogue-text').toggleClass('is-expanded');
});

$('.js-toggle-categories-filter').click(function () {
  $(this).closest('.js-categories-filter').toggleClass('is-collapsed');
});

$(window).on('resize initSpoilersState', function () {
  if (!(0, _helpers.IS_DESKTOP_WIDTH)()) {
    $('.js-categories-filter').addClass('is-collapsed');
  } else {
    $('.js-categories-filter').removeClass('is-collapsed');
  }
}).trigger('initSpoilersState');

$('.js-toggle-tags').click(function () {
  $(this).closest('.js-tags-list').toggleClass('is-expanded');
});

$('.js-toggle-brands-group').click(function () {
  $(this).closest('.js-brands-group').toggleClass('is-expanded');
});

$('.js-toggle-mobile-spoiler').click(function () {
  $(this).closest('.js-mobile-spoiler').toggleClass('is-collapsed');
});

},{"./helpers":10}],20:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function (e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function () {
    $(this).find('.tabs-nav li:first a').click();
  });
});

},{}],21:[function(require,module,exports){
'use strict';

$(document).tooltip({
  items: '.js-logo-tooltip',
  content: function content() {
    return $('<img>').attr('src', $(this).data('logo')).addClass('tooltip-logo');
  },

  position: { my: 'right+100% bottom-20', at: 'right center' }
});

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пирмер подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {selectedId: {type: NumberConstructor}, offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListComponent = {
  props: {
    selectedId: {
      type: Number
    },
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id == id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) classArray.push(this.selectedClass);

      if (offer.disabled) classArray.push(this.disabledClass);

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListComponent;

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 */

/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
var PriceComponent = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    defaultAmount: {
      type: Number
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency">руб.</span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Спец. цена, звоните!</span>'
    }
  },
  data: function data() {
    return {
      amount: this.defaultAmount
    };
  },

  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return (this.price * this.amount).toLocaleString('ru-RU') + this.currencySuffix;

      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0) return '<span class="value">' + (this.oldPrice * this.amount).toLocaleString('ru-RU') + '</span>' + this.currencySuffix;

      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  },
  methods: {
    smartRound: function smartRound(val) {
      if (String(val).indexOf('.') !== -1) {
        val = val.toFixed(2);
        val = String(val).replace(/0$/, '');
        val = Number(val.replace(/.0$/, ''));
      }
      return val;
    }
  }
};

exports.default = PriceComponent;

},{}]},{},[1]);
